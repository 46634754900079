// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-getting-started-js": () => import("./../../../src/pages/getting-started.js" /* webpackChunkName: "component---src-pages-getting-started-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-templates-accessories-js": () => import("./../../../src/templates/Accessories.js" /* webpackChunkName: "component---src-templates-accessories-js" */),
  "component---src-templates-doc-category-js": () => import("./../../../src/templates/DocCategory.js" /* webpackChunkName: "component---src-templates-doc-category-js" */),
  "component---src-templates-doc-js": () => import("./../../../src/templates/Doc.js" /* webpackChunkName: "component---src-templates-doc-js" */),
  "component---src-templates-guide-js": () => import("./../../../src/templates/Guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/Recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-templates-support-js": () => import("./../../../src/templates/Support.js" /* webpackChunkName: "component---src-templates-support-js" */)
}

