module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://cookwithcarbon.com"},
    },{
      plugin: require('../node_modules/gatsby-styled-components-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"bodyBg":"#1E1E1E","headerBgColor":"#282828","cardTextColor":"#f0f0f0","cardTextColorDark":"#d9d9d9","brandPrimaryTextColor":"#C6AF8B","formFieldColor":"#f0f0f0","cardBgColor":"#3c3b39","cardBgColorDark":"#282828","cardBgColorLight":"#424242","cardBorderColor":"#565656"},"dark":{"bodyBg":"#1E1E1E","headerBgColor":"#282828","cardTextColor":"#f0f0f0","cardTextColorDark":"#d9d9d9","brandPrimaryTextColor":"#C6AF8B","formFieldColor":"#f0f0f0","cardBgColor":"#3c3b39","cardBgColorDark":"#282828","cardBgColorLight":"#424242","cardBorderColor":"#565656"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
